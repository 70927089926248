import React, { Fragment } from 'react';
import ABTest from '../../abtest/components/abtest';
import CustomModal from '../../shared/components/custom-modal';
import { Helpers } from '../../core/src/helpers';
import { ModalControl } from '../../context/modal/modal-context';
import { hasXsell } from '../../xsell/xsell-actions';
import XSELL_TEMPLATES from '../../xsell/xsell-templates';
import ECarePackPopup from './ecarepack-popup';
import loadable from '@loadable/component';
//TODO: remove this temporary fix for excluding optimizely for BF/CM 2020
import DEFAULT_CUSTOM_MODAL from './custom-purchase-modal';

import '../css/purchase-button.less';
const XSellModal = loadable(() => Helpers.retryFunc(() => import('../../xsell/components/xsell-modal')));

class PurchaseModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            xsell: {},
        };
    }

    getTemplate = (isPrecheckedModal, isV2) => {
        let template;
        if (isV2) {
            template = XSELL_TEMPLATES.TABSV2;
        } else if (isPrecheckedModal) {
            template = XSELL_TEMPLATES.PRECHECKED;
        } else {
            template = XSELL_TEMPLATES.TABS;
        }
        return template;
    };

    onPurchase = (openModal, launchCartFlyout) => {
        const { product, fetchXSellProducts, isV2 } = this.props;
        let limit = 25;
        const source = 'all';
        fetchXSellProducts(product, limit, source, isV2).then(xSellProducts => {
            const isProductInkPrinter = Helpers.getPrinterType(product) === 'ink';
            const isPrecheckedModal =
                !!xSellProducts.productGroup &&
                xSellProducts.productGroup.productsList &&
                xSellProducts.productGroup.productsList.length > 0 &&
                isProductInkPrinter;
            if (!hasXsell(xSellProducts, isV2)) {
                launchCartFlyout && launchCartFlyout();
                return;
            }
            limit = isPrecheckedModal ? 2 : 25; // swap with definition after abtest
            this.setState(
                { xsell: { product, xSellProducts, template: this.getTemplate(isPrecheckedModal, isV2), limit } },
                () => openModal()
            );
        });
    };

    render() {
        const {
            product,
            price,
            quantity,
            addToCart,
            customModalBlocked,
            jumpid,
            isV2,
            ctaMetrics,
            baseProductSku,
            xsellType,
            templateKey,
            popupConfig,
            productClickMetrics,
        } = this.props;
        const { sku } = product;
        return (
            <ABTest experimentID="11078077827" ssrRender={true}>
                {data => {
                    const customModalData = data[sku] || DEFAULT_CUSTOM_MODAL[sku] || null;
                    const custom_cta = customModalData && !customModalBlocked;
                    const isProductInkPrinter = Helpers.getPrinterType(product) === 'ink';
                    const isECarepack = Helpers.isECarepack(product);

                    let contentProps = isECarepack
                        ? { product, quantity, addToCart, templateKey }
                        : Object.assign(this.state.xsell, { product, templateKey });
                    let modalContent = isECarepack ? ECarePackPopup : XSellModal;

                    if (ctaMetrics) contentProps.ctaMetrics = ctaMetrics;
                    if (baseProductSku) contentProps.baseProductSku = baseProductSku;
                    if (xsellType) contentProps.xsellType = xsellType;
                    if (productClickMetrics) contentProps.productClickMetrics = productClickMetrics;

                    if (typeof popupConfig === 'object' && popupConfig) {
                        contentProps = {
                            ...contentProps,
                            ...popupConfig,
                        };
                    }
                    let modalProps = {
                        wrapperClassName: isECarepack ? '' : 'xsell-bg',
                        className: isECarepack
                            ? 'ecarepack-modal-container'
                            : `xsell-modal-container${
                                  isProductInkPrinter && this.state.xsell.template === 'PRECHECKED'
                                      ? '-printer'
                                      : '-default'
                              }${isV2 ? ' v2' : ''}`,
                        closeOnBGClick: isECarepack ? false : true,
                    };
                    // override cta function if targeted through optimizely
                    if (custom_cta) {
                        contentProps = { product, price, jumpid, ...customModalData };
                        modalContent = CustomModal;
                        modalProps = { className: customModalData.modal };
                    }
                    return (
                        <ModalControl
                            content={modalContent}
                            contentProps={contentProps}
                            modalProps={modalProps}
                            dynamicContent={true}
                        >
                            {props => {
                                return (
                                    <Fragment>
                                        {this.props.children(
                                            Object.assign({}, props, {
                                                customModalData: customModalData,
                                                onPurchase: this.onPurchase,
                                            })
                                        )}
                                    </Fragment>
                                );
                            }}
                        </ModalControl>
                    );
                }}
            </ABTest>
        );
    }
}

export default PurchaseModal;
