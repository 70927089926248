import React from 'react';
import PropTypes from 'prop-types';

import Icon from './icon';

import './button.less';

function Button({ text, disable, icon, color, size, type, variation, action, linkComponent, onClick, added, className, children }) {
    if (disable)
        return (
            <div
                className={`button-animated disable ${type} ${color} ${size}${variation ? ` ${variation}-align`:''}${
                    className ? ' ' + className : ''
                }`}
            >
                {text}
            </div>
        );

    let derivedIcon = icon;
    let iconSize = 20;
    switch (true) {
        case action === 'go': {
            derivedIcon = 'arrow_right';
            break;
        }
        case action === 'back': {
            derivedIcon = 'arrow_left';
            break;
        }
        case action === 'add': {
            derivedIcon = 'plus';
            iconSize = 19;
            break;
        }
        case action === 'remove': {
            derivedIcon = 'x';
            iconSize = 17;
            break;
        }
        case action === 'none':
        default:
    }
    const customComponent = linkComponent || children;
    return (
        <button
            type="button"
            className={`button-animated ${type} ${color} ${size} ${action}${variation ? ` ${variation}-align`:''}${className ? ' ' + className : ''}`}
            onClick={onClick}
            tabIndex={linkComponent ? -1 : 0}
        >
            <span className="position-container">
                {customComponent && customComponent}
                {!customComponent && <span className="label">{text}</span>}
                <span>{derivedIcon && <Icon name={derivedIcon} size={iconSize} />}</span>
            </span>
            {action === 'add' && (
                <span className={`success ${added ? 'active'   :   'inactive'}`}>
                    <Icon name="check" size={iconSize} />
                </span>
            )}
        </button>
    );
}

Button.defaultProps = {
    text: 'button',
    disable: false,
    icon: null,
    color: 'primary',
    size: 'large',
    type: 'primary',
    variation: '',
    linkComponent: null,
    action: 'go',
    added: false,
};

Button.propTypes = {
    /** Button text */
    text: PropTypes.string,
    disable: PropTypes.bool,
    /** see icon component for valid icon name props */
    icon: PropTypes.string,
    /** color style */
    color: PropTypes.oneOf(['primary', 'dark', 'white']),
    /** size style */
    size: PropTypes.oneOf(['small', 'large']),
    /** type style */
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    /** variation for tertiary button styles */
    variation: PropTypes.oneOf(['', 'left', 'right']),
    /** if to is populated button makes use of the Link component for href navigation */
    linkComponent: PropTypes.object,
    /** action animation */
    action: PropTypes.oneOf(['go', 'back', 'add', 'remove', 'none']),
    /** activate add animation */
    added: PropTypes.bool,
};

export default Button;
