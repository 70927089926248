import React from 'react';

import QuantityPicker from './quantity-picker';
import { Helpers } from '../../core/src/helpers';
import { withError } from '../../shared/components/error-boundary';
import Link from '../../shared/components/hyperlink';
import withWindowResize from '../../ui/components/window-resize';
import Button from '../../ui/components/button';
import CTOAddToCart from './cto-add-to-cart';
import AddToQuote from './add-to-quote';
import PurchaseModal from './purchase-modal';

import '../css/purchase-button.less';

const EMPTY_OBJECT = {};
const suppliesSearchUrl = `${process.env.BASENAME}/cv/supplies-search`;
const NO_PRICE = {
    PRT: 1,
    EXT: 1,
};

class PurchaseButton extends React.Component {
    state = {
        quantity: 0,
        customModalOnce: true,
        btnBusy: false,
    };

    static defaultProps = {
        onClick: null,
        product: {},
        addOns: [],
        CTOQuery: {},
        forceOOS: false,
        enableCTOAddToCart: false,
        disableXsellPopup: false,
    };

    static getDerivedStateFromProps(props, state) {
        //don't change quantity after initial load
        if (state.quantity > 0) {
            return null;
        }

        if (props.isMultiple) {
            return { quantity: props.qty };
        } else {
            return { quantity: 1 };
        }
    }

    componentDidMount() {
        const { price, product } = this.props;
        if (price.regularPrice === '' && product.itemId && !(product.prdClass in NO_PRICE)) {
            this.props.fetchPrices(product.itemId);
        }
    }

    componentDidUpdate(prevProps) {
        const { price, product } = this.props;
        if (
            prevProps.product.itemId !== product.itemId &&
            price.regularPrice === '' &&
            !(product.prdClass in NO_PRICE)
        ) {
            this.props.fetchPrices(product.itemId);
        }
    }

    purchase = (event, openModal, customData, onPurchase) => {
        const {
            price,
            product,
            addToCart,
            addOns,
            device,
            isV2,
            variant,
            xSellOpenedSkus = EMPTY_OBJECT,
            disableXsellPopup,
            forceOOS,
            launchCartFlyout,
            baseProductSku,
            xsellType,
            metrics,
            confirmAddToCart,
            serialNumber,
        } = this.props;
        const { Devdependency } = product || EMPTY_OBJECT;
        const { deviceDependency } = price || EMPTY_OBJECT;
        const hasDeviceDependency = (deviceDependency || Devdependency) !== 'NO';

        const { quantity } = this.state;
        const { suppliesLinkUrl, pm_producttype } = product.attributes || EMPTY_OBJECT;

        if (forceOOS) {
            //force OOS should override all other purchase button functionality
            return;
        } else if (price.preOrder) {
            return (
                confirmAddToCart &&
                confirmAddToCart('preorder', [product, quantity, addOns, variant, baseProductSku, xsellType, metrics])
            );
        }
        if (suppliesLinkUrl) {
            const { pdpConfig = EMPTY_OBJECT } = product;
            const { pdpDetails = EMPTY_OBJECT } = pdpConfig;
            if (pdpDetails.replacementProduct) {
                window.location = `${suppliesSearchUrl}?searchTerm=${product.sku}`;
            } else {
                window.location = suppliesLinkUrl;
            }
        } else if (product.prdClass === 'CTO' && !product.eol) {
            this.setState({ btnBusy: true }, () => {
                this.customizeAndBuy();
            });
        } else if (Helpers.isECarepack(product) && !(baseProductSku || serialNumber) && hasDeviceDependency) {
            confirmAddToCart &&
                confirmAddToCart('ecarepack', [product, quantity, addOns, variant, baseProductSku, xsellType, metrics]);
        } else if (price.stock === undefined || price.stock > 0) {
            this.setState({ btnBusy: true });

            addToCart(product, quantity, true, addOns, variant, baseProductSku, xsellType, metrics)
                .then(() => {
                    this.setState({ btnBusy: false });
                    if (
                        product.bundle ||
                        (device === 'mobile' && !isV2) ||
                        (pm_producttype || '').toLowerCase() === 'monitors' ||
                        product.sku in xSellOpenedSkus ||
                        disableXsellPopup
                    ) {
                        launchCartFlyout();
                    } else {
                        onPurchase(openModal, launchCartFlyout);
                    }
                })
                .catch(e => {
                    this.setState({ btnBusy: false });
                });
        }
    };

    openCustomModal = (event, openModal, data) => {
        const { customModalOnce } = this.state;
        const { once } = data;
        if (customModalOnce) {
            this.setState({ customModalOnce: !once });
            openModal();
        } else {
            this.purchase(event);
        }
    };

    customizeAndBuy() {
        const { quantity } = this.state;
        const { product, CTOQuery, jumpid, additionalParams = EMPTY_OBJECT, price } = this.props;
        let params = additionalParams;
        if (jumpid) {
            params.jumpid = jumpid;
        }
        if (CTOQuery) {
            params = { ...params, ...CTOQuery };
        }
        setTimeout(() => {
            window.location = Helpers.addUrlParam(price.ctoLink, params);
        }, 100);
    }

    getCTAText = () => {
        const { price, product, variant, customCtaText, forceOOS, priceLabels } = this.props;
        const { metadata, prdClass, attributes = EMPTY_OBJECT, isComingSoon, pdpConfig = EMPTY_OBJECT } = product;
        const { pdpDetails = EMPTY_OBJECT } = pdpConfig;
        let { isOOS } = price;
        const { suppliesLinkUrl } = attributes;
        let { ctaText } = metadata || EMPTY_OBJECT;
        const { preOrder: preOrderLabel = 'Pre-order now', poboOOSMsg = 'Pre-orders sold out' } = priceLabels || {};

        let text = customCtaText ? customCtaText : prdClass === 'CTO' ? 'Customize & buy' : 'Add to cart';

        if (forceOOS) {
            return 'Out of stock';
        }

        if (suppliesLinkUrl) {
            if (pdpDetails.replacementProduct) {
                return 'Shop ink or toner';
            }
            return 'View supplies';
        }

        if (price.preOrder) {
            return isOOS ? poboOOSMsg : preOrderLabel;
        }
        if (product.isComingSoon && isOOS) {
            return 'Coming soon';
        }

        //TEMP: do someting special for coming soon, it should only appear if the product is OOS
        if (ctaText && ctaText.toLowerCase() !== 'coming soon' && !isOOS) {
            text = ctaText;
        } else if (ctaText && isOOS) {
            //override OOS with coming soon cta
            return ctaText;
        }
        if (price.stock === 0 && prdClass !== 'CTO') {
            text = 'Out of stock';
        }

        return text;
    };

    getAriaLabel = () => {
        try {
            return `${this.getCTAText().replace(/ \& /gi, ' and ')} for ${this.props.product.name}`;
        } catch (error) {
            return '';
        }
    };

    setQuantity = quantity => {
        this.setState({ quantity });
    };

    getProductClass = () => {
        const { price, product, forceOOS, enableCTOAddToCart } = this.props;
        const { attributes = EMPTY_OBJECT, isComingSoon, pdpConfig = EMPTY_OBJECT } = product;
        const { suppliesLinkUrl, ctoAddToCart } = attributes;
        const { pdpDetails = EMPTY_OBJECT } = pdpConfig;
        let prdClass;
        if (forceOOS) {
            prdClass = 'OOS';
        } else if (!suppliesLinkUrl && product.prdClass !== 'PRT' && Helpers.isProductOffline(price)) {
            prdClass = 'offline-product';
        } else if (product.eol) {
            if (suppliesLinkUrl && pdpDetails.replacementProduct) {
                prdClass = 'shop-ink';
            } else if (suppliesLinkUrl) {
                prdClass = 'view-supplies';
            } else {
                prdClass = 'OOS';
            }
        } else if (price.stock === 0 && !isComingSoon) {
            prdClass = 'OOS';
        } else if (product.prdClass === '3PP') {
            prdClass = 'THREEPP';
        } else {
            prdClass = product.prdClass;
        }

        //add custom class for cto add to cart
        if (ctoAddToCart && enableCTOAddToCart) {
            prdClass += ' cto-a2c';
        }

        return prdClass;
    };

    render() {
        const {
            metrics,
            showSelector,
            showSelectorHeader = false,
            product,
            price,
            onClick,
            jumpid,
            customModalBlocked,
            variant,
            quantity,
            addToCart,
            enableCTOAddToCart,
            forceOOS,
            enableAddToQuote,
            addOns,
            templateKey,
            popupConfig,
            isccf,
            isV2,
            color = 'primary',
            baseProductSku,
            xsellType,
        } = this.props;
        const { sku, metadata, attributes = EMPTY_OBJECT, pdpConfig = EMPTY_OBJECT } = product;
        const { suppliesLinkUrl } = attributes;
        const { ctaText } = metadata || EMPTY_OBJECT;
        const isComingSoon = (ctaText && ctaText.toLowerCase() === 'coming soon') || product.isComingSoon;
        const isGiftCard = Helpers.isGiftCard(product);
        const replacementLink = (pdpConfig.pdpDetails || EMPTY_OBJECT).replacementProduct;
        const quantityPicker =
            showSelector && product.prdClass !== 'CTO' ? (
                <div className="quantity-picker-container">
                    {showSelectorHeader && <span className="quantity-picker-header">Quantity: </span>}
                    <QuantityPicker quantity={quantity} onPick={this.setQuantity} enabled={price.stock !== 0} />
                </div>
            ) : null;
        const disabled = forceOOS || (price.stock === 0 && !(suppliesLinkUrl || isComingSoon));
        const prdClass = this.getProductClass();
        const isOOS = (price.isOOS && product.prdClass !== 'CTO') || forceOOS;
        const displayAddToQuote =
            enableAddToQuote && isccf && isOOS && !product.isComingSoon && product.prdClass === 'STO';
        if (product.prdClass === 'CTO' && product.eol) {
            return null;
        }

        if (isGiftCard && !variant) {
            //if it's a gift card and no variant information is provided link to pdp
            return (
                <div className={`purchase-btn-container${prdClass ? ` ${prdClass}` : ''}`}>
                    <Link to={product.linkUrl} className="btn btn-primary purchase-btn">
                        {'Customize & buy'}
                    </Link>
                </div>
            );
        }

        let cta_function;
        const btnText = this.getCTAText();
        return (
            <PurchaseModal
                jumpid={jumpid}
                product={product}
                price={price}
                quantity={quantity}
                addToCart={addToCart}
                customModalBlocked={customModalBlocked}
                fetchXSellProducts={this.props.fetchXSellProducts}
                launchCartFlyout={this.props.launchCartFlyout}
                isV2={isV2}
                baseProductSku={baseProductSku}
                xsellType={xsellType}
                ctaMetrics={metrics}
                templateKey={templateKey}
                {...(popupConfig || {})}
            >
                {props => {
                    if (props.customModalData) {
                        cta_function = this.openCustomModal;
                    } else {
                        cta_function = this.purchase;
                    }
                    return (
                        <div className={`purchase-btn-container${prdClass ? ` ${prdClass}` : ''}`}>
                            {quantityPicker}
                            <div className="buy-button-container">
                                {replacementLink && (
                                    <div className="view-replacement btn">
                                        <Link to={replacementLink}>View Replacement Printer</Link>
                                    </div>
                                )}
                                {!isV2 && (
                                    <button
                                        className={`btn btn-primary purchase-btn${disabled ? ' disabled' : ''}${
                                            this.state.btnBusy ? ' busy' : ''
                                        }`}
                                        aria-label={this.getAriaLabel()}
                                        onClick={e => {
                                            if (typeof onClick === 'function') {
                                                onClick(() => {
                                                    cta_function(
                                                        e,
                                                        props.openModal,
                                                        props.customModalData,
                                                        props.onPurchase,
                                                    );
                                                });
                                            } else {
                                                cta_function(
                                                    e,
                                                    props.openModal,
                                                    props.customModalData,
                                                    props.onPurchase,
                                                );
                                            }
                                        }}
                                    >
                                        {btnText}
                                    </button>
                                )}
                                {/* in the new version, add to quote button replaces regular oos button. In the old version, add to quote appears next to oos in pdps, not enabled in product cards*/}
                                {isV2 && !displayAddToQuote && (
                                    <Button
                                        type={'tertiary'}
                                        variation={'left'}
                                        disable={disabled}
                                        text={btnText}
                                        color={color}
                                        onClick={e => {
                                            if (typeof onClick === 'function') {
                                                onClick(() => {
                                                    cta_function(
                                                        e,
                                                        props.openModal,
                                                        props.customModalData,
                                                        props.onPurchase,
                                                    );
                                                });
                                            } else {
                                                cta_function(
                                                    e,
                                                    props.openModal,
                                                    props.customModalData,
                                                    props.onPurchase,
                                                );
                                            }
                                        }}
                                        action={
                                            product.prdClass === 'CTO' || btnText === 'View supplies' ? 'go' : 'add'
                                        }
                                        className={'purchase'}
                                        added={this.state.btnBusy}
                                    />
                                )}
                                {displayAddToQuote && (
                                    <AddToQuote
                                        product={product}
                                        quantity={this.state.quantity}
                                        isOOS={isOOS}
                                        addOns={addOns}
                                        variant={variant}
                                        isV2={isV2}
                                    />
                                )}
                                {enableCTOAddToCart && <CTOAddToCart product={product} />}
                            </div>
                        </div>
                    );
                }}
            </PurchaseModal>
        );
    }
}

export { PurchaseButton as _UnconnectedPurchaseButton };
export default withWindowResize(withError(PurchaseButton));
