/**
 * TODO: Make this better, this was quick add to be able to disable optimizely for BF/CM 2020
 */
export default (function () {
    // printer names
    var names = {
        0: "Original HP Sprocket",
        1: "HP Sprocket 2nd Edition",
        2: "HP Sprocket 2-in-1",
        3: "HP Sprocket Plus",
        4: "HP Sprocket Select",
        5: "HP Sprocket Studio"
    };

    // will add commas and "or" between the last two elems
    var getListString = function (list, useAnd) {
        var conj = useAnd ? " and " : " or ";
        if (list.length > 1) {
            var lastIdx = list.length - 1;
            return list.slice(0, lastIdx).join(", ") + conj + list[lastIdx] + " Photo Printers";
        } else {
            return list[0] + " Photo Printer";
        }
    };

    // will generate lists of compatible and incompatible strings
    var getCompatStrings = function (compatible, overrideStrings) {
        var compat = [];
        var noncompat = [];
        var override = overrideStrings || {};

        for (var i = 0; i < Object.keys(names).length; i++) {
            if (compatible[i]) {
                compat.push(names[i]);
            } else {
                noncompat.push(names[i]);
            }
        }

        var result = [];
        if (compat.length) {
            result.push(override.compat || ("Compatible with " + getListString(compat, true)));
        }
        result.push('   '); // delimeter line
        if (noncompat.length) {
            result.push(override.noncompat || ("Not compatible with " + getListString(noncompat)));
        }

        return result;
    };

    // will recreate the base object every invokation effectively cloning it
    var getCommonValues = function () {
        return {
            modal: 'zink-paper',
            once: true,
            header: 'Is this the right Sprocket paper for you?',
            footer: 'Need a different size paper?',
            altcta: 'Shop now',
            url: 'https://store.hp.com/app/slp/sprocket-photo-printers/paper'
        };
    };

    // 2 x 3 in
    var zink2x3 = Object.assign(getCommonValues(), {
        body: getCompatStrings({
            0: true,
            1: true,
            2: true
        })
    });

    // 2.3 x 3.4 in
    var zink23x34 = Object.assign(getCommonValues(), {
        body: getCompatStrings({
            3: true,
            4: true
        })
    });

    // 4 x 6 in
    var zink4x6 = Object.assign(getCommonValues(), {
        body: getCompatStrings({
            5: true
        }, {
            noncompat: "Not compatible with any other HP Sprocket Photo Printer"
        })
    });
    return {
        "1AH01A": zink2x3,
        "1DE39A": zink2x3,
        "1DE40A": zink2x3,
        "2FR23A": zink23x34,
        "4KK83A": zink4x6
    }
})();


