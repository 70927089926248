import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
    addToCart,
    addMultipleToCart,
    launchCartFlyout,
    addCTOToCart,
    updateCart,
    updateCartBySKU,
} from '../../utility/utility-actions';
import { confirmAddToCart } from '../../utility/components/add-to-cart-confirm/add-to-cart-confirm-actions';
import PurchaseButton, { _UnconnectedPurchaseButton } from './purchase-button';
import { Helpers } from '../../core/src/helpers';
import { fetchXSellProducts } from '../../xsell/xsell-actions';
import { fetchPrices } from '../product-actions';
import useProductPrice from '../../hooks/useProductPrice';

const mapStateToProps = (state, ownProps) => {
    const { product, addOns } = ownProps;
    const { productData, etrStoreData } = state;
    const { productInfo, ctoConfigurations } = productData;
    const { labels: priceLabels } = etrStoreData;
    return {
        showSelector: ownProps.showSelector,
        product,
        price: Helpers.getProductPrice(product, productInfo.productPrices, true, ctoConfigurations),
        priceLabels,
        jumpid: ownProps.jumpid,
        xSellModalBlocked: state.xSell.blocked,
        xSellModalIsOpen: state.xSell.isOpen,
        xSellOpenedSkus: state.xSell.xSellOpenedSkus,
        addToCartFlyoutVisible: state.cartInfo.addToCartFlyoutVisible,
        isccf: (state.userData.profileData || {}).isccf,
        addOns,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCart: (item, qty) => dispatch(updateCart(item, qty)),
        updateCartBySKU: (sku, qty) => dispatch(updateCartBySKU(sku, qty)),
        addCTOToCart: (product, quantity, blockFlyout, followRedirect) =>
            dispatch(addCTOToCart(product, quantity, blockFlyout, followRedirect)),
        addToCart: (product, quantity, blockFlyout, addOns, options, baseProductSku, xsellType, metricParams) =>
            dispatch(
                addToCart(product, quantity, blockFlyout, addOns, options, baseProductSku, xsellType, metricParams)
            ),
        addMultipleToCart: (products, quantity, blockFlyout, options, baseProductSku, xsellType) =>
            dispatch(addMultipleToCart(products, quantity, blockFlyout, options, baseProductSku, xsellType)),
        fetchXSellProducts: (product, limit, source, isV2) =>
            dispatch(fetchXSellProducts(product, limit, source, isV2)),
        fetchPrices: Helpers.memoize(id => dispatch(fetchPrices([id]))),
        confirmAddToCart: (confirmType, params) => dispatch(confirmAddToCart(confirmType, params)),
        launchCartFlyout: payload => dispatch(launchCartFlyout(payload)),
    };
};

const WithAddToCart = Component => {
    return props => {
        return <Component {...props} />;
    };
};
const withAddToCart = compose(connect(null, mapDispatchToProps), WithAddToCart);

const withPurchaseButton = compose(connect(mapStateToProps, mapDispatchToProps), Component => {
    return props => {
        const { product } = props;
        const { prices } = useProductPrice([product]);
        const price = product && prices[product.sku];
        return <Component {...props} price={price} />;
    };
});

export { _UnconnectedPurchaseButton, withAddToCart, withPurchaseButton };
export default connect(mapStateToProps, mapDispatchToProps)(PurchaseButton);
