import React from 'react';
import { connect } from 'react-redux';
import {Button} from '@hpstellar/core';
import { Plus } from '@hpstellar/icons';

import { addCTOToCart } from '../../utility/utility-actions';
import { withError } from '../../shared/components/error-boundary';
import withWindowResize from '../../ui/components/window-resize';
import useAddToCart from '../../hooks/useAddToCart';

import '../css/cto-add-to-cart.less';

const purchaseCTO = (ctoAddToCart, product, quantity) => () => {
    ctoAddToCart(product, quantity, true);
};

export const CTOAddToCartComponent = withError(({ mobileOnly = true, device, product, quantity = 1, gtmProps }) => {
    const { addCTOToCart } = useAddToCart();
    //TODO: temp till product initial has the boolean for CTO add to cart
    const prdClass = product.product_type || product.prdClass;

    if ((mobileOnly && device !== 'mobile') || prdClass !== 'CTO' || !product) {
        return null;
    }
    return (
        <Button
            onClick={purchaseCTO(addCTOToCart, product, quantity)}
            endIcon={<Plus size="s" />}
            variation="secondary"
            className="cto-a2c"
            {...(gtmProps || {})}
        >
            Add to cart
        </Button>
    );
});

class CTOAddToCart extends React.PureComponent {
    static defaultProps = {
        mobileOnly: true,
        mobileSpacer: true,
    };

    state = {
        btnBusy: false,
    };

    purchaseCTO = (quantity = 1) => {
        const { product } = this.props;
        this.props.addCTOToCart(product, quantity, true);
    };

    btnClick = () => {
        return () => {
            this.setState(
                {
                    btnBusy: true,
                },
                () => {
                    setTimeout(this.purchaseCTO, 0);
                }
            );
        };
    };

    render() {
        const { mobileOnly, device, mobileSpacer, product, isV2, gtmProps } = this.props;
        const { attributes = {} } = product;
        const { ctoAddToCart } = attributes;
        //TODO: temp till product initial has the boolean for CTO add to cart
        const prdClass = product.product_type || product.prdClass;

        if ((mobileOnly && device !== 'mobile') || prdClass !== 'CTO' || !product) {
            return null;
        }
        return isV2 ? (
            <Button
                onClick={this.btnClick()}
                endIcon={<Plus size="s" />}
                color="white"
                className="cto-a2c"
                {...(gtmProps || {})}
            >
                Add to cart
            </Button>
        ) : (
            <div className="cto-add-to-cart">
                {mobileSpacer && <div className="cto-mobile-spacer">{' or '}</div>}
                <div className={`btn${this.state.btnBusy ? ' busy' : ''}`} onClick={this.btnClick()}>
                    {'ADD TO CART'}
                </div>
                <div className="preconfig-disclaimer">{' Pre-configurated for you! '}</div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addCTOToCart: (product, quantity, blockFlyout) => dispatch(addCTOToCart(product, quantity, blockFlyout)),
    };
};

export default connect(null, mapDispatchToProps)(withError(withWindowResize(CTOAddToCart)));
