import React from 'react';
import { withError } from '../../shared/components/error-boundary';
import { addToCart, launchCartFlyout } from '../../utility/utility-actions';
import Button from '../../ui/components/button';
import { connect } from 'react-redux';

class AddToQuote extends React.PureComponent {
    addToQuote = () => {
        const { product, quantity, addOns, variant, launchCartFlyout, addToCart } = this.props;
		addToCart(product, quantity, true, addOns, variant).then(() => {
			launchCartFlyout();
		})
    }
    render() {
        const { isV2 } = this.props;
        return isV2 ? (
            <Button 
                onClick={this.addToQuote}
                type={'tertiary'}
                text={'Add to quote'}
                action={'add'}
                className={'add-to-quote'}
            />
        ) : (
            <button className="add-to-quote btn btn-primary purchase-btn" onClick={this.addToQuote}>
                Add To Quote
            </button>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (product, quantity, blockFlyout, addOns, variant) => dispatch(addToCart(product, quantity, blockFlyout, addOns, variant)),
        launchCartFlyout: () => dispatch(launchCartFlyout())
    };
};

export default connect(null, mapDispatchToProps)(withError(AddToQuote));
