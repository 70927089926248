const PRECHECKED = "PRECHECKED";
const TABS = "TABS";
const TABSV2 = "TABSV2";
const TWOUPV2 = "TWOUPV2";

export default {
    PRECHECKED,
    TABS,
    TABSV2,
    TWOUPV2
};
