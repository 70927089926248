import React from 'react';
import { Helpers } from '../../core/src/helpers';
import Link from './hyperlink';
import ImageWithPlaceholder from './image-with-placeholder';
import PurchaseButtonContainer from '../../product/components/purchase-button-container';
import '../../product/css/purchase-button.less';
import '../css/custom-modal.less';

class CustomModal extends React.Component {


    renderModalBody(body){
        return body.map( (paragraph, i) => {
            return ( <p key={i} dangerouslySetInnerHTML={ Helpers.createMarkup(paragraph) }></p> );
        });
    }

    render() {
        const { header,
                modal,
                body,
                product,
                price,
                jumpid,
                footer,
                altcta,
                url,
                closeModal } = this.props;

        return (
            <div className={`custom-modal`}>
                <h2 className="attention">{header}</h2>
                { this.renderModalBody(body) }
                <ImageWithPlaceholder src={product.imageUrl} alt={product.name}/>
                {<PurchaseButtonContainer
                    product={product}
                    jumpid={jumpid}
                    customModalBlocked={true} />}
                <p className="attention-text" dangerouslySetInnerHTML={ Helpers.createMarkup(footer) } ></p>
                <Link to={url} className="btn custom-modal-btn" onClick={() => { closeModal() }}>{altcta}</Link>
            </div>
        );
    }
};

export default CustomModal;
