import React from 'react';
import { connect } from 'react-redux';

import { StoreAppAPI } from '../../core/src/storeapp-api-client';
import ETRWCSClient from '../../core/src/etr-wcs-api-client';
import { Helpers } from '../../core/src/helpers';
import { withError } from '../../shared/components/error-boundary';
import ImageWithPlaceholder from '../../shared/components/image-with-placeholder';

import '../css/ecarepack-popup.less';

let storeAppAPI = new StoreAppAPI();

const popupText = {
    title: 'Which product would you like to protect?',
    subtitle:
        'If you are also purchasing the hardware for this carepack now, please add the hardware to your cart first!',
    headerText:
        'When you select items from your cart, the corresponding number of Care Packs is added. To add a Care Pack to fewer items, please place separate orders for any items you do not want to cover with a Care Pack.',
    serialNumberMsg: "Please enter your eligible product's serial number",
    serialErrMsg: 'This serial number is invalid. Please try again, or scroll down for help.',
    arrowIcon: 'https://store.hp.com/wcsstore/HPStorefrontAssetStore/img/hero/pdp/cp_addProduct_icon_arrow.jpg',
    supportMsg: "Can't find what you need? <br>Please call Order Support at 1-866-635-0242",
    howToSection: {
        imgSrc: 'https://www.hp.com/wcsstore/HPStorefrontAssetStore/img/hero/pdp/cp_serialNumber_sticker.jpg',
        howToTitle: 'How do I find my serial number?',
        paragraphs: [
            {
                subtitle: 'Desktops & All-in-Ones',
                content:
                    "Some products utilize the keyboard shortcut 'Ctrl + Alt + S'; the product information will appear after a short delay. If that doesn't work, this information can also be found on your bar code stickers, typically located on the back of your product.",
            },
            {
                subtitle: 'Notebooks',
                content:
                    "Some products utilize the keyboard shortcut 'Fn + Esc'; the product information will appear after a short delay. If that doesn't work, this information can also be found on your bar code stickers, which may be located behind the battery.",
            },
            {
                subtitle: 'Printers (Both HP and Samsung)',
                content:
                    "Look for the bar code stickers on your printer.The Serial number on a Samsung printer can be used wherever an HP serial number is requested. Samsung printers refer to a 'product number' as a 'model code' designated with 'M/C', and typically start with 'SL-_'.",
            },
            {
                subtitle: 'Tablets',
                content: 'Your product information is typically located on the back or bottom edge of your product.',
            },
        ],
        learnMore: {
            text: 'Learn more about finding your Serial Number',
            link: 'https://support.hp.com/us-en/document/c04559742',
        },
    },
};
//Place holder cartItem for user input
const defaultOption = {
    name: 'Another product I own',
    qty: null,
    img: 'https://www.hp.com/us-en/shop/app/assets/images/icons/cp_addProduct_icon.png?imwidth=100&impolicy=prdimg&imdensity=1',
    pNum: 'userInput',
};

const getCartItemId = (carePackCatEntryId, cartItems) => {
    //match ETR logic, get order it of final match if more than one of the same sku
    let cartItemId;
    (cartItems || []).forEach(ci => {
        if (ci.ceId === carePackCatEntryId && ci.isStandAlooneCrPack === true) {
            cartItemId = ci.id;
        }
    });
    return cartItemId;
};

class HardwareSelector extends React.PureComponent {
    selectHardware = (index, hw) => {
        this.setState({
            selectedIndex: index,
        });
        this.props.onSelect(hw);
    };

    state = {
        selectedIndex: null,
    };

    render() {
        const { hardwareSkus } = this.props;
        const { selectedIndex } = this.state;

        if (hardwareSkus.length === 0) {
            return null;
        }

        return (
            <ul className="hardware-selector clearfix">
                {hardwareSkus.map((hw, i) => {
                    const { img, name, qty, pNum } = hw;
                    return (
                        <li
                            key={i}
                            className={`${i === selectedIndex ? 'selected ' : ''}${pNum}`}
                            onClick={() => {
                                this.selectHardware(i, hw);
                            }}
                        >
                            <ImageWithPlaceholder src={img} />
                            <h3 dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(name))}></h3>
                            {qty && <p>Qty: {qty}</p>}
                        </li>
                    );
                })}
            </ul>
        );
    }
}

class ECarePackPopup extends React.PureComponent {
    static defaultProps = {
        product: {},
    };

    constructor(props) {
        super(props);
        this.state = {
            SN: '',
            SNValid: false,
            SNVerified: false,
            buttonBusy: false,
            hardwareSkus: [],
            errorMessage: '',
        };
    }

    componentDidMount() {
        const { product, cartItems, cartAssociations } = this.props;
        const { sku } = product;
        if (!!sku && cartItems.length > 0) {
            this.fetchHardwareSkus(sku, cartItems, cartAssociations);
        }
    }

    componentDidUpdate(prevProps) {
        const { product, cartItems, cartAssociations } = this.props;
        const { sku } = product;
        if (prevProps.product.sku !== sku && cartItems.length > 0) {
            this.fetchHardwareSkus(sku, cartItems, cartAssociations);
        }
    }

    /**
     * Fetch hardwards skus for the given eCarepack
     * @return {[type]} [description]
     */
    fetchHardwareSkus = (sku, cartItems, cartAssociations) => {
        //filter cart items only select those that don't already have associated cart items
        const products = cartItems.reduce((r, item) => {
            const { pNum, cId } = item;
            let associatedKey = `${pNum}${cId ? `|${cId}` : ''}`;
            if (!(associatedKey in (cartAssociations || {}))) {
                r.push(item.wrntyCe || item.ceId);
            }
            return r;
        }, []);
        storeAppAPI.product.details(sku, 'hardware', { relatedProducts: products }).then(hardware => {
            const { relatedProducts } = hardware;
            //map results with cartItems and set state
            const hardwareSkus = relatedProducts.reduce((r, itemId) => {
                const hw = cartItems.find(ci => ci.wrntyCe === itemId || ci.ceId === itemId);
                if (hw) {
                    r.push(hw);
                }
                return r;
            }, []);
            if (hardwareSkus.length > 0) {
                hardwareSkus.push(defaultOption);
            }

            this.setState({
                hardwareSkus,
            });
        });
    };

    handleInputChange = e => {
        this.setState({
            SN: e.target.value,
            SNValid: this.validateSerialNumber(e.target.value),
            SNVerified: false,
        });
    };

    handleVerificationResponse = (resp, exception) => {
        const { obligationResJson } = resp || {};
        const { statusCode, invalidSNs, isDuplicateSN } = obligationResJson || {};
        if (exception || ![200, 201, 0].includes(statusCode)) {
            return this.setState({
                SNVerified: false,
                errorMessage: 'Please try again...',
                buttonBusy: false,
            });
        }
        if (invalidSNs && invalidSNs.length > 0) {
            return this.setState({
                SNVerified: false,
                errorMessage: 'Please enter a valid serial number. The number input was not found in our records.',
                buttonBusy: false,
            });
        }
        if (isDuplicateSN) {
            return this.setState({
                SNVerified: false,
                errorMessage: 'Entered serial number is already added to the order',
                buttonBusy: false,
            });
        }

        this.setState({ SNVerified: true, errorMessage: '' }, () => {
            this.addECPToCart();
        });
    };

    validateSerialNumber = sn => {
        return sn.length >= 5;
    };

    handleHardwareSelect = hardwareSku => {
        this.setState({
            selectedHardware: hardwareSku,
            SNValid: hardwareSku.pNum !== defaultOption.pNum || this.validateSerialNumber(this.state.SN),
        });
    };

    addECPToCart = () => {
        this.setState({ buttonBusy: true });
        const {
            addToCart,
            product,
            quantity,
            closeModal,
            ctaMetrics,
            baseProductSku,
            xsellType,
            isccf,
            enableSerialNumberVerification,
            cartItems,
        } = this.props;
        const { selectedHardware, SN, SNVerified } = this.state;
        let qty = quantity;
        //if it's the user inputed value, use userInputBaseSKU
        //Otherwise if they selected from list of eligble in-cart products, use baseSKU
        let options = {};
        if (selectedHardware && selectedHardware.pNum !== defaultOption.pNum) {
            //include cId if cartItem has it
            options.baseSKU = selectedHardware.pNum + (selectedHardware.cId ? `|${selectedHardware.cId}` : '');
            qty = selectedHardware.qty;
        } else if (enableSerialNumberVerification && !SNVerified) {
            const catEntryId = product.catEntryId || product.itemId;
            //make validation call
            return ETRWCSClient.obligationService
                .eCarePackSerialNumberValidation(SN, catEntryId, isccf, getCartItemId(catEntryId, cartItems))
                .then(this.handleVerificationResponse)
                .catch(e => {
                    this.handleVerificationResponse({}, e);
                });
        } else {
            options.userInputBaseSKU = SN;
        }

        addToCart(product, qty, false, {}, options, baseProductSku, xsellType, ctaMetrics)
            .then(() => {
                closeModal();
            })
            .catch(e => {
                this.setState({ buttonBusy: false }, () => closeModal());
            });
    };

    render() {
        const { buttonBusy, SNValid, hardwareSkus, selectedHardware, errorMessage } = this.state;
        const { title, subtitle, serialNumberMsg, serialErrMsg, howToSection, headerText, arrowIcon, supportMsg } =
            popupText;
        const { howToTitle, imgSrc, paragraphs, learnMore } = howToSection;
        const hardwareSelectClass =
            (!selectedHardware && hardwareSkus.length > 0) ||
            (selectedHardware && selectedHardware.pNum !== defaultOption.pNum)
                ? ' hardware-selected'
                : '';

        return (
            <div className={'ecarepack-popup-container' + hardwareSelectClass}>
                <h1 className="ecarepack-title">{title}</h1>
                {hardwareSkus.length > 0 ? (
                    <p className="header-text">{headerText}</p>
                ) : (
                    <p className="subtitle">{subtitle}</p>
                )}
                <HardwareSelector hardwareSkus={hardwareSkus} onSelect={this.handleHardwareSelect} />
                <div className="serial-container">
                    {selectedHardware && selectedHardware.pNum === defaultOption.pNum && (
                        <div className="cp-top-arrow">
                            <img style={{ left: `${[70, 10, 40][hardwareSkus.length % 3]}%` }} src={arrowIcon} alt="" />
                        </div>
                    )}
                    <div className="enter-serial">
                        <span>{serialNumberMsg}</span>
                        <input
                            type="text"
                            id="serial-textbox"
                            className="disabled"
                            placeholder="MY12345"
                            maxLength="10"
                            onChange={this.handleInputChange}
                        ></input>
                        {errorMessage && <span className="verification-error-message">{errorMessage}</span>}
                        <span className="serial-error-message">{serialErrMsg}</span>
                        <button
                            id="continue"
                            className={`${buttonBusy ? 'busy' : ''} ${SNValid ? '' : 'disabled'}`}
                            onClick={() => this.addECPToCart()}
                        >
                            Continue
                        </button>
                        {supportMsg && (
                            <div
                                className="support-message"
                                dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(supportMsg))}
                            />
                        )}
                    </div>
                </div>
                <div className="how-to">
                    <ImageWithPlaceholder src={imgSrc} />
                    <h2>{howToTitle}</h2>
                    {paragraphs.map((p, i) => (
                        <div key={i}>
                            <h3>
                                <strong>{p.subtitle}</strong>
                            </h3>
                            <p>{p.content}</p>
                        </div>
                    ))}
                    <p className="learn-more">
                        <a href={learnMore.link} target="_blank">
                            {learnMore.text}
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state, ownProps) => {
    const { cartInfo, userData, siteConfig } = state;
    const { items: cartItems, ordItmFld2: cartAssociations } = cartInfo;
    const { profileData } = userData;
    const { isccf } = profileData;
    //TODO: temp variable so we can deploy this feature ahead of time and toggle it on when the ETR api is available
    const { enableSerialNumberVerification } = siteConfig;
    return {
        cartItems,
        cartAssociations,
        isccf,
        enableSerialNumberVerification,
    };
};

export default withError(connect(mapStateToProps, null)(ECarePackPopup));
